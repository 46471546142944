"use strict";

(function () {
  function documentReady() {
    /**
     * Версия для es5
     * Создает массив MediaQueryList и следит за его обновлением
     *
     * @param  {object} queriesList - Объект вида { default: foo, '(min-width: 760px)': bar }
     * @param  {function} func - коллбэк функция, первый параметр которой - текущее значение,
     * а второй - строка с текущим медиа запросом
     */
    function MediaHandler(queriesList, func) {
      if (typeof func !== 'function') {
        func = function func(f) {
          if (typeof f === 'function') {
            f();
          }
        };
      }

      var defaultQuery;
      var queryList;
      var defaultValue = queriesList.default;
      var queries = $.extend({}, queriesList);
      delete queries.default;
      defaultQuery = {
        query: 'default',
        value: defaultValue
      };
      queryList = Object.keys(queries).map(function (query) {
        var value = queries[query];
        return {
          list: window.matchMedia(query),
          value: value,
          query: query
        };
      });
      queryList.forEach(function (object) {
        object.list.addListener(setCurrentQuery);
      });
      setCurrentQuery();

      this.destroy = function () {
        queryList.forEach(function (object) {
          object.list.removeListener(setCurrentQuery);
        });
        queryList = null;
      };

      function setCurrentQuery() {
        var current = queryList.filter(function (object) {
          return object.list.matches;
        })[0] || defaultQuery;
        func(current.value, current.query);
      }
    }

    ;
    /**
     * Версия для es5
     * Следит за классами DOM-объекта и вызывает функцию при изменении набора классов.
     *
     * @param  {DOM-object} el - объект, за которым ведется наблюдение.
     * @param  {function} func - коллбэк функция, первый параметр которой - добавленные классы,
     * а второй - убранные классы
     */

    function ClassEvent(el, func) {
      var old;
      var mo = new MutationObserver(callback);
      mo.observe(el, {
        attributes: true,
        attributeOldValue: true
      });
      mo.old = old;
      return mo;

      function callback(mutationsList) {
        var newClass = $.makeArray(el.classList);
        var oldValue = mutationsList[0].oldValue;
        var oldClass = old || (oldValue ? oldValue.split(' ') : []);
        var added = newClass.filter(changed, oldClass);
        var removed = oldClass.filter(changed, newClass);
        func(added, removed, newClass, oldClass);
        old = newClass;
      }

      function changed(e) {
        return this.indexOf(e) < 0;
      }
    }

    function observeClasses(el, _class, show, reset) {
      var classArray = _class.split(' ');

      show = typeof show === 'function' ? show : function () {};
      reset = typeof reset === 'function' ? reset : function () {};
      return new ClassEvent(el, function (added, removed, all, old) {
        if (classArray.length > 1) {
          if (!test(old, classArray) && test(all, classArray)) {
            show();
          } else if (test(old, classArray) && !test(all, classArray)) {
            reset();
          }
        } else {
          if (test(added, classArray)) {
            show();
          } else if (test(removed, classArray)) {
            reset();
          }
        }
      });

      function test(arr, probe) {
        return probe.every(function (e) {
          return arr.indexOf(e) >= 0;
        });
      }
    }

    ;
    /**
     * Версия для es5
     * Устанавливает геттер и сеттер в объект
     */

    function defineSetter(obj, functions) {
      var shadow = {};
      Object.keys(functions).forEach(function (key) {
        if (key in obj) {
          return;
        }

        Object.defineProperty(obj, key, {
          get: function get() {
            return shadow[key];
          },
          set: function set(val) {
            var old = shadow[key];

            if (val !== old) {
              shadow[key] = val;
              functions[key](val, old);
            }
          }
        });
      });
    }

    ;
    /*
    {
        '10% - 20%': function(progress) {},
        '50%': function(progress) {},
    }
     animationstart
    animationend
    */

    function AnimationQueue(list, duration, throttle) {
      var IN_ACTION = 'action';
      var OUT = 'out;';
      var percentRe = new RegExp('([.0-9]+)%', 'gi');
      var secondRe = new RegExp('([.0-9]+)s', 'gi');
      var millisecondRe = new RegExp('([.0-9]+)ms', 'gi');
      var timeStart = null;
      var firedList = [];
      var self = this;
      var stopFlag;
      var frame = 0;
      throttle = throttle || 1;

      if (isNaN(duration)) {
        throw new TypeError('invalid arguments');
      }

      Object.defineProperty(this, 'begin', {
        get: function get() {
          return timeStart;
        }
      });
      Object.defineProperty(this, 'end', {
        get: function get() {
          return timeStart ? timeStart + duration : null;
        }
      });
      Object.defineProperty(this, 'inAction', {
        get: function get() {
          return Boolean(timeStart);
        }
      });

      this.start = function () {
        if (!self.inAction) {
          requestAnimationFrame(step);
        }
      };

      this.stop = function () {
        if (self.inAction) {
          stopFlag = true;
        }
      };

      this.list = Object.keys(list).map(function (key, id) {
        var start;
        var end;
        var timing = key.split('-');
        timing.forEach(function (e, i, arr) {
          var str = e.trim();
          var units = 1;
          units = /([.0-9]+)%/.test(str) ? 0.01 : units;
          units = /([.0-9]+)s/.test(str) ? 1000 / duration : units;
          units = /([.0-9]+)ms/.test(str) ? 1 / duration : units;
          arr[i] = parseFloat(str) * units;
        });
        return {
          id: id,
          start: isNaN(timing[0]) ? -1 : timing[0],
          end: isNaN(timing[1]) ? timing[0] : timing[1],
          func: typeof list[key] === 'function' ? list[key] : function () {}
        };
      });

      function step(timestamp) {
        var progress;
        frame++;

        if (!timeStart && typeof self.animationstart === 'function') {
          self.animationstart.call(self);
        }

        timeStart = timeStart || timestamp;

        if (timestamp <= self.end && !stopFlag) {
          requestAnimationFrame(step);
        } else {
          stopFlag = null;
          timeStart = null;
          firedList = [];
          frame = 0;

          if (typeof self.animationend === 'function') {
            self.animationend.call(self);
          }

          return;
        }

        if (frame % throttle) {
          return;
        }

        progress = map(timestamp, self.begin, self.end, 0, 1);
        self.list.filter(function (e) {
          var inAction = e.start <= progress && progress <= e.end; // одиночный вызов

          if (e.start === e.end && e.start <= progress && !firedList[e.id]) {
            firedList[e.id] = OUT;
            e.func.call(self, 0, 'once');
            return false;
          } // первый вызов


          if (inAction && !firedList[e.id]) {
            firedList[e.id] = IN_ACTION;
            e.func.call(self, 0, 'first');
            return false;
          } // последний вызов


          if (!inAction && firedList[e.id] === IN_ACTION) {
            firedList[e.id] = OUT;
            e.func.call(self, 1, 'last');
          }

          return inAction;
        }).forEach(function (e) {
          e.func.call(self, map(progress, e.start, e.end, 0, 1));
        });
      }
    }

    function map(x, inMin, inMax, outMin, outMax) {
      return (x - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
    }

    ;
    var OFFSET = 0.3;
    var INSIDE_PATTERNS = ['bebe', 'beeb', 'ebeb', 'ebbe'];
    var ANIMATION_CLASS = '.js-on-visible'; // с точкой

    var HIDDEN_CLASS = 'animated'; // без точки

    var ANIMATION_HIDDEN_CLASS = ANIMATION_CLASS + '.' + HIDDEN_CLASS;
    var VIEWPORT_CLASS = '.js-is-visible'; // с точкой

    var IN_CLASS = 'in-viewport'; // без точки

    var HOLD_CLASS = 'hold-outside-viewport'; // без точки

    $(document).ready(function () {
      var thr_checkEdges = throttle(checkEdges, 200);
      var $elements = $(ANIMATION_CLASS + ',' + VIEWPORT_CLASS);

      if ($elements.length) {
        $(window).off('scroll', thr_checkEdges);
        $('.os-viewport').on('scroll', thr_checkEdges);
        checkEdges();
        $elements.each(function () {
          observeClasses(this, HOLD_CLASS, checkEdges, checkEdges);
        });
      }

      function checkEdges(inside, outside) {
        var viewportHeight = window.innerHeight;
        var top = OFFSET * viewportHeight;
        var bottom = (1 - OFFSET) * viewportHeight;
        $(ANIMATION_HIDDEN_CLASS).each(function () {
          if (!this.classList.contains(HOLD_CLASS) && test(this)) {
            this.classList.remove(HIDDEN_CLASS);
          }
        });
        top = 0;
        bottom = viewportHeight;
        $(VIEWPORT_CLASS).each(function () {
          this.classList.toggle(IN_CLASS, !this.classList.contains(HOLD_CLASS) && test(this));
        });

        function test(el) {
          var box = el.getBoundingClientRect();
          var pattern = [['b', top], ['b', bottom], ['e', box.top], ['e', box.bottom]].sort(function (a, b) {
            return a[1] - b[1];
          }).map(function (e) {
            return e[0];
          }).join('');
          return INSIDE_PATTERNS.indexOf(pattern) >= 0;
        }
      }
    });

    function throttle(func, ms) {
      var isThrottled = false;
      var savedArgs;
      var savedThis;
      return function () {
        if (isThrottled) {
          savedArgs = arguments;
          savedThis = this;
          return;
        }

        isThrottled = true;
        setTimeout(function () {
          isThrottled = false;
          func.apply(savedThis, savedArgs);
          savedArgs = savedThis = null;
        }, ms);
      };
    }

    ;
    /* eslint-disable */

    var PopupLoader = function (window) {
      // Параметры по умолчанию
      var defaultPopupList = [{
        name: 'gallery',
        path: '/ajax/popup_photo.php',
        // path: '/json/popup-gallery.json',
        template: 'popup-gallery',
        resolve: function resolve() {
          startSwiper();
          window.gallerySwiperInit && gallerySwiperInit();
          window.gallerySwiperRefresh && gallerySwiperRefresh();
        }
      }, {
        name: 'gallery-video',
        path: '/ajax/popup_video.php',
        // path: '/json/popup-gallery-video.json',
        template: 'popup-video',
        resolve: function resolve() {
          startSwiper();
          window.gallerySwiperInit && gallerySwiperInit();
          window.gallerySwiperRefresh && gallerySwiperRefresh();
        }
      }, {
        name: 'feedback',
        path: '/ajax/popup_feedback.php',
        // path: '/json/popup-feedback.json',
        resolve: function resolve() {
          $('body').trigger('initValidation');
        }
      }]; // Параметры для всего

      var globalSettings = {
        popupList: []
      }; // Глобальные параметры window

      if (!('PL' in window)) {
        window.PL = {
          defaultPopupList: []
        };
      } // Установка/Замешение параметров


      globalSettings.popupList = window.PL.defaultPopupList.length ? window.PL.defaultPopupList : defaultPopupList; // globalSettings.popupList = $.extend({}, defaultPopupList, window.PL.defaultPopupList);

      /**
       * Создание и Добовление html в DOM
       * @param {String} target любой селектор
       * @param {HTMLElement | String} html тут либо html строка или элемент
       */

      function _createOnHtml(target, html) {
        $(target).append(html);
      }
      /**
       * Класс конструктор
       */


      function PopupLoader() {
        this.popupList = globalSettings.popupList;
        this.targetAppend = 'body';
        this.params = {};
      }
      /**
       * Проверка на загрузку попапа по имени
       * @param {String} name имя попапа
       * @returns {Boolean} true/false
       */


      PopupLoader.prototype.isLoadByName = function (name) {
        return $('.b-popup[data-popup=' + name + ']').length > 0;
      };
      /**
       * Проверка на наличие
       * @param {String} name имя попапа
       * @returns {Any | undefined}
       */


      PopupLoader.prototype.isFinInListByName = function (name) {
        return this.popupList.find(function (item) {
          return item.name === name;
        });
      };

      PopupLoader.prototype.setParams = function (params) {
        this.params = params;
      };

      PopupLoader.prototype.clearParams = function () {
        this.params = {};
      };
      /**
       * Загрузка
       * @param {String} name имя попапа
       * @param {Function} callback обратный вызов в случаэ когда попап небыл загружен и его загрузили
       */


      PopupLoader.prototype.load = function (name, callback) {
        var target = null;
        var find = this.isFinInListByName(name); // if (!this.isLoadByName(name) && name in this.popupList) {

        if (!this.isLoadByName(name) && find) {
          target = this.targetAppend; // Ajax

          $.ajax({
            method: 'GET',
            url: find.path,
            data: this.params,
            success: function success(result) {
              var popupHtml = '';

              if (find) {
                if ('template' in find && find.template && window.templateStore[find.template]) {
                  popupHtml = window.templateStore[find.template].render(result);
                } else if (typeof result === 'string') {
                  popupHtml = result;
                }

                if (popupHtml) {
                  _createOnHtml(target, popupHtml);

                  find.resolve();
                  callback({
                    success: true,
                    popupHtml: popupHtml,
                    error: false
                  });
                }
              } // console.group('load: ');
              // console.log('window.templateStore: ', window.templateStore);
              // console.log('result: ', result);
              // console.log('popupHtml: ', popupHtml);
              // console.log('find: ', find);
              // console.groupEnd();
              // _createOnHtml(target, popupHtml);
              // find.resolve();
              // callback({
              //     success: true,
              //     popupHtml: popupHtml,
              //     error: false
              // });

            },
            error: function error(_error) {
              console.error(_error);
              callback({
                success: false,
                popupHtml: '',
                error: _error
              });
            }
          });
        } else if (this.isLoadByName(name) && find) {
          find.resolve();
          callback({
            success: true,
            popupHtml: '',
            error: false
          });
        }

        this.clearParams();
      };

      return PopupLoader;
    }(window);

    ;

    (function (window) {
      /* eslint-disable */
      var templateStore = {}; // Popup

      templateStore['popup'] = {
        template: '' + '<div class="b-popup b-popup--gallery" data-popup="{{popup}}">' + '<div class="b-popup__content">{{{content}}}</div>' + '</div>',
        render: function render(result) {
          return Mustache.render(this.template, result);
        }
      }; // card photo

      templateStore['photo-card'] = {
        template: '' + '<div class="b-gallery__item swiper-slide">' + '<div class="b-gallery__group">' + '<div class="b-gallery__image-wrapper">' + '<picture>' + '<source srcset="{{srcMobileWebp}}" media="(max-width: 767px)" type="image/webp">' + '<source srcset="{{srcMobileJpg}}" media="(max-width: 767px)" type="image/jpg">' + '<source srcset="{{srcTableWebp}}" media="(max-width: 1919px)" type="image/webp">' + '<source srcset="{{srcTableJpg}}" media="(max-width: 1919px)" type="image/jpg">' + '<source srcset="{{srcDeskWebp}}" media="" type="image/webp">' + '<img class="b-gallery__image" src="{{srcDeskJpg}}" alt="" role="presentation">' + '</picture>' + '</div>' + '</div>' + '</div>',
        render: function render(result) {
          return Mustache.render(this.template, result);
        },
        renderList: function renderList(result) {
          return Mustache.render('{{#items}}' + this.template + '{{/items}}', result);
        }
      }; // card video TODO

      templateStore['video-card'] = {
        template: '' + '<div class="b-gallery__item swiper-slide">' + '<div class="b-gallery__group">' + '<div class="b-media">' + '<iframe width="100%" height="100%" data-src="{{video}}?autoplay=1" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>' + '</div>' + '<div class="b-gallery__name">{{title}}</div>' + '</div>' + '</div>',
        render: function render(result) {
          return Mustache.render(this.template, result);
        },
        renderList: function renderList(result) {
          return Mustache.render('{{#items}}' + this.template + '{{/items}}', result);
        }
      }; // Popup-video

      templateStore['popup-video'] = {
        template: '' + '<buttom class="b-popup__close js-close-popup" aria-label="Close popup">' + '<i class="b-icon b-icon--close-popup icon-close"></i>' + '</buttom>' + '<div class="b-gallery b-gallery--video swiper js-gallery">' + '<div class="b-gallery__wrapper swiper-wrapper">{{{items}}}</div>' + '<button class="b-button b-button--back b-button--gallery swiper-button-next"><span></span></button>' + '<button class="b-button b-button--back b-button--gallery swiper-button-prev"><span></span></button>' + '<div class="swiper-pagination"></div>' + '</div>',
        render: function render(result) {
          var templateVideo = Mustache.render(this.template, {
            items: templateStore['video-card'].renderList(result)
          });
          return templateStore['popup'].render({
            popup: 'gallery-video',
            content: templateVideo
          });
        }
      }; // Popup-gallery

      templateStore['popup-gallery'] = {
        template: '' + '<a class="b-popup__close js-close-popup" href="javascript:void(0);">' + '<i class="b-icon b-icon--close-popup icon-close"></i>' + '</a>' + '<div class="b-gallery swiper js-gallery">' + '<div class="b-gallery__wrapper swiper-wrapper">{{{items}}}</div>' + '<button class="b-button b-button--back b-button--gallery swiper-button-next"><span></span></button>' + '<button class="b-button b-button--back b-button--gallery swiper-button-prev"><span></span></button>' + '<div class="swiper-pagination"></div>' + '</div>',
        render: function render(result) {
          var templatePhoto = Mustache.render(this.template, {
            items: templateStore['photo-card'].renderList(result)
          });
          return templateStore['popup'].render({
            popup: 'gallery',
            content: templatePhoto
          });
        }
      };
      window.templateStore = templateStore;
    })(window);

    ;

    if ($('.js-accordion').length) {
      var openAccordion = function openAccordion() {
        $('.js-accordion-button').on('click', function () {
          var $this = $(this);

          if ($(this).hasClass('open')) {
            $(this).removeClass('open').addClass('closing').siblings('.js-accordion-content').slideUp(300);
          } else {
            $('.js-accordion-button').removeClass('open').removeClass('closing').siblings('.js-accordion-content').slideUp(300);
            $(this).removeClass('closing').addClass('open').siblings('.js-accordion-content').slideDown(300);
            setTimeout(function () {
              $('.b-page-wrapper').scrollTo($this[0], 300, {
                offset: 0
              });
            }, 350);
          }
        });
      };

      openAccordion();
    }

    ;
    var windowsOS = navigator.userAgent.toLowerCase().indexOf('windows') !== -1;
    var $page = $('.b-page-wrapper');

    function getScroll(scroll, selector) {
      var doc = document;
      var body = doc.body;
      var element = doc.querySelector(selector);
      var client = 'client' + scroll;
      scroll = 'scroll' + scroll;
      return /CSS/.test(doc.compatMode) ? element[client] < element[scroll] : body[client] < body[scroll];
    }

    $('.js-burger-btn').on('click', function () {
      if ($(this).parent().hasClass('active')) {
        $(this).parent().find('.b-burger__expand').fadeOut(300);
        $page.removeClass('no-scroll windows');
      } else {
        $(this).parent().find('.b-burger__expand').fadeIn(300);

        if (windowsOS && getScroll('Height', '.b-page-wrapper')) {
          $page.addClass('windows');
        }

        $page.addClass('no-scroll');
      }

      $(this).parent().toggleClass('active');
      $('.b-page-wrapper').toggleClass('open-burger');
    });
    $('body').on('feedback-form', function () {
      $('.js-burger-btn').parent().removeClass('active');
      $('.js-burger-btn').parent().find('.b-burger__expand').hide();
      $('.b-page-wrapper').removeClass('open-burger');
    });
    ;

    (function (window) {
      /* eslint-disable no-console */
      // Параметры
      var $gallery = $('.js-gallery');
      var $galleryList = $('.js-gallery-list');
      var swiper = undefined;
      var lastClick = undefined;
      var isUpdate = true;
      var isPopupVideo = false; // Функции

      var templateSlide = undefined;
      var generateSlide = undefined;
      var dataParse = undefined;
      var gallerySwiperRefresh = undefined;
      var gallerySwiperInit = undefined;
      var gellerySwiperVideoGoTo = undefined; // Порверка и старт

      if ($galleryList.length) {
        gallerySwiperInit = function gallerySwiperInit() {
          swiper = new Swiper('.js-gallery', {
            loop: true,
            pagination: {
              el: '.swiper-pagination',
              type: 'fraction'
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },
            on: {
              slideChange: function slideChange(sw) {
                gellerySwiperVideoGoTo();
              }
            }
          });
          window.gellerySwiper = swiper;
        }; // Шаблон слайда


        templateSlide = function templateSlide(params) {
          return '<div class="b-gallery__item swiper-slide">' + '<div class="b-gallery__group">' + '<div class="b-gallery__image-wrapper"><picture>' + params.picture + '</picture></div>' + '<div class="b-gallery__name">' + params.name + '</div>' + '</div>' + '</div>';
        }; // Получаем данные


        dataParse = function dataParse($photo) {
          return {
            name: $photo.find('.js-name').text(),
            picture: $photo.find('.js-images').html()
          };
        }; // Генерируем список


        generateSlide = function generateSlide() {
          var $photos = $galleryList.children().not('.in-gallery-slide');
          var len = $galleryList.children('.in-gallery-slide').length;
          var slideList = [];
          $photos.each(function (index) {
            var params = dataParse($(this));
            var newElement = templateSlide(params);
            slideList.push(newElement);
            $(this).addClass('in-gallery-slide');
            $(this).attr('data-gallery-index', index + len + 1);
          });
          return slideList;
        }; // Переход на видео


        gellerySwiperVideoGoTo = function gellerySwiperVideoGoTo(offAll) {
          var $AllIframes = undefined;
          offAll = offAll || false; // console.group('gellerySwiperVideoGoTo: ');
          // console.log('offAll: ', offAll);
          // console.groupEnd();

          setTimeout(function () {
            $AllIframes = $gallery.find('iframe');
            $AllIframes.each(function () {
              var $iframe = $(this);

              if (offAll) {
                $iframe.attr('src', '');
              } else {
                if ($iframe.parents('.swiper-slide-active').length) {
                  $iframe.attr('src', $iframe.data('src'));
                } else {
                  $iframe.attr('src', '');
                }
              }
            });
          }, 300);
        }; // Обновляем


        gallerySwiperRefresh = function gallerySwiperRefresh() {
          var newSlidesList = [];
          $gallery = $('.js-gallery');
          newSlidesList = generateSlide(); // console.group('gallerySwiperRefresh');
          // console.log('swiper: ', swiper);
          // console.log('$gallery: ', $gallery);
          // console.log('isPopupVideo: ', isPopupVideo);
          // console.groupEnd();

          if (swiper) {
            if (isUpdate) {
              setTimeout(function () {
                swiper.update();
              }, 200);
              isUpdate = false;
            }

            if (lastClick) {
              // Переключение
              swiper.slideTo(lastClick, 0, true);
              lastClick = undefined;
            } // Запуск видео


            if (isPopupVideo) {
              gellerySwiperVideoGoTo();
              isPopupVideo = false;
            }
          }
        }; // Клик по слайдеру


        $(document).on('click', '.in-gallery-slide', function () {
          var index = Number($(this).attr('data-gallery-index'));
          var popupName = $(this).data('popup');
          isPopupVideo = popupName === 'gallery-video'; // Переключение

          if (swiper) {
            swiper.slideTo(index, 0, true); // Запуск видео

            if (isPopupVideo) {
              gellerySwiperVideoGoTo();
            }
          } else {
            lastClick = index;
          }
        });

        if ($gallery.length) {
          gallerySwiperInit();
        }

        setTimeout(function () {
          gallerySwiperRefresh();
        }, 100);
      }

      window.gallerySwiperRefresh = gallerySwiperRefresh;
      window.gallerySwiperInit = gallerySwiperInit;
      window.gellerySwiperVideoGoTo = gellerySwiperVideoGoTo;
    })(window);

    ;

    function isIE() {
      var ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object

      var msie = ua.indexOf('MSIE '); // IE 10 or older

      var trident = ua.indexOf('Trident/'); //IE 11

      return msie > 0 || trident > 0;
    } //function to show alert if it's IE


    function ShowIEAlert() {
      if (isIE()) {
        $('html').addClass('no-object-fit');
      } else {
        $('html').addClass('object-fit');
      }
    }

    ShowIEAlert();
    var userAgent = window.navigator.userAgent;

    if (userAgent.match(/Mac/i)) {
      if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
        $('html').addClass('ios');
      } else {
        $('html').addClass('mac');
      }
    } // if (userAgent.match(/Xiao/i)) {
    //     $('body').addClass('xiaomi')
    // }


    ;

    if ($('.js-scale-detect').length && $(window).innerWidth() < 768 && $('.js-scale-detect').outerHeight() > 78) {
      $('.b-page-wrapper').addClass('scaled');
    }

    ;
    $(document).ready(function () {
      objectFitImages();
    });
    ; // ====================
    // robinzon: popup.js
    // 15.01.2018: Amedomary
    // ---------------------
    // Открытие попАпа и запрет скрола на body
    // ====================

    $(document).ready(function () {
      /* eslint-disable */
      var $page = $('.b-page-wrapper'); // var $popUp = $('.b-popup');
      // var $popUpContent = $('.b-popup__content');

      var popupLoader = new PopupLoader();
      var windowsOS = navigator.userAgent.toLowerCase().indexOf('windows') !== -1;
      $('.js-open-popup').on('popup.onClose', function (event) {
        closePopUp();
      }); // Закрытие попАпов

      function closePopUp() {
        var $popUp = $('.b-popup'); // Проверяем открыт ли попАп
        // if ($popUp.hasClass('open')) {
        //     $page.removeClass('no-scroll no-touch windows');
        //     $popUp.removeClass('open');
        //     $popUp.stop().fadeOut(300);
        // }

        if ($popUp.hasClass('open')) {
          $popUp.removeClass('open');
          $popUp.stop().fadeOut(300);
          setTimeout(function () {
            $page.removeClass('no-scroll no-touch windows');
          }, 220);
        }

        $('body').trigger('feedback-form');
        window.gellerySwiperVideoGoTo && gellerySwiperVideoGoTo(true);
      } // Проверка на наличие скролаа


      function getScroll(scroll, selector) {
        var doc = document;
        var body = doc.body;
        var element = doc.querySelector(selector);
        var client = 'client' + scroll;
        scroll = 'scroll' + scroll;
        return /CSS/.test(doc.compatMode) ? element[client] < element[scroll] : body[client] < body[scroll];
      } // Клик по ссылке открывающей попАп


      $(document).on('click', '.js-open-popup', function () {
        $(this).trigger('popup.onOpen');
      }); // Кастомное событие открытие попАп

      $(document).on('popup.onOpen', '.js-open-popup', function () {
        openPopUp.call(this);
      });

      function openPopUp(popup) {
        var $popUp = $('.b-popup');
        var $popUpDate = undefined;
        var op = undefined;
        var $this = $(this);
        var section = $this.attr('data-section') || '';
        popup = popup || $(this).attr('data-popup');
        $popUpDate = $('.b-popup[data-popup=\'' + popup + '\']'); // Создаем типа callback

        $(this).trigger('popup.open', [$popUpDate]);

        op = function op() {
          $popUp = $('.b-popup');
          $popUpDate = $('.b-popup[data-popup=\'' + popup + '\']'); // Проверяем операционную систему на Win и Скролл

          if (windowsOS && getScroll('Height', '.b-page-wrapper')) {
            $page.addClass('windows');
          } // Закрываем перед открытиме другие


          $popUp.removeClass('open');
          $popUp.fadeOut(300);
          $page.addClass('no-scroll no-touch');
          $popUpDate.addClass('open');
          $popUpDate.css('display', 'flex').hide().fadeIn(300); // if ($(this).attr('data-popup') == 'feedback') {
          //     // $('body').trigger('openFeedback');
          //     $('body').trigger('feedback-form');
          // }
        }; // Проверяем есть ли нам что открыть


        if ($popUpDate.length > 0) {
          op.call(this);
        } else {
          var _ = this; // Если нет, по пробуем загрузить
          // Если попап уже загружен, то все ок он сразу откроется
          // Если попапа нет, не загружен и нет в списке загрузок, то не откроется


          if (section) {
            popupLoader.setParams({
              section_id: section
            });
          }

          popupLoader.load(popup, function () {
            setTimeout(function () {
              op.call(_);
            }, 0);
          });
        }
      }

      window.openPopUp = openPopUp; // Клик по Закрытию попАпов

      $(document).on('click', '.js-close-popup', function () {
        closePopUp();

        if ($('.js-menu-in-popup-back').length) {
          $('.js-menu-in-popup-back').trigger('click');
        }
      }); // Жмяк по Esc

      $(document).on('keydown', function (event) {
        if (event.keyCode === 27) {
          closePopUp();
        }
      }); // $(document).mouseup(function (e) {
      //     if ($popUp.hasClass('open')) {
      //         // Клик не по Контенту и не его дочкам
      //         if (!$popUpContent.is(e.target)&& $popUpContent.has(e.target).length === 0) {
      //             closePopUp();
      //         }
      //     }
      // });
      // $('body').on('feedback-success', function () {
      //     openPopUp('feedback-success');
      // });
    });
    ;

    if ($('.js-dropdown-btn').length) {
      var useDropdown = function useDropdown() {
        $('.js-dropdown-btn').on('click', function () {
          if ($(this).hasClass('open')) {
            $(this).removeClass('open').text('Подробнее').prev().removeClass('open').slideUp(600);
          } else {
            $(this).addClass('open').text('Свернуть').prev().addClass('open').slideDown(300);
          }
        });
      };

      useDropdown();
    }

    ;
    $(document).ready(function () {
      var SETTINGS = {
        ignore: '.ignore, [type="hidden"]',
        messages: {
          agreement: {
            required: 'Согласитесь с политикой конфиденциальности'
          }
        },
        focusCleanup: true
      };
      $.validator.setDefaults({
        debug: true
      });
      $.extend($.validator.messages, {
        required: 'Напишите email',
        remote: 'Пожалуйста, введите правильное значение.',
        email: 'Некорректно заполнен e-mail',
        url: 'Пожалуйста, введите корректный URL.',
        date: 'Пожалуйста, введите корректную дату.',
        dateISO: 'Пожалуйста, введите корректную дату в формате ISO.',
        number: 'Пожалуйста, введите число.',
        digits: 'Пожалуйста, вводите только цифры.',
        creditcard: 'Пожалуйста, введите правильный номер кредитной карты.',
        equalTo: 'Пожалуйста, введите такое же значение ещё раз.',
        extension: 'Пожалуйста, выберите файл с правильным расширением.',
        maxlength: $.validator.format('Пожалуйста, введите не больше {0} символов.'),
        minlength: $.validator.format('Пожалуйста, введите не меньше {0} символов.'),
        rangelength: $.validator.format('Пожалуйста, введите значение длиной от {0} до {1} символов.'),
        range: $.validator.format('Пожалуйста, введите число от {0} до {1}.'),
        max: $.validator.format('Пожалуйста, введите число, меньшее или равное {0}.'),
        min: $.validator.format('Пожалуйста, введите число, большее или равное {0}.')
      });
      $.validator.addMethod('js-email', function (value, element) {
        var regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if ($(element).hasClass('js-email')) {
          if ($(element).prop('required') || value.replace(/\s/g, '').length) {
            return regEmail.test(value);
          } else {
            return true;
          }
        } else {
          return true;
        }
      }, $.validator.messages.email);
      $.validator.addMethod('js-letters-only', function (value, element) {
        return this.optional(element) || /^[A-Za-zА-Яа-яЁё]*$/.test(value);
      }, 'Только буквы');
      $.validator.addMethod('js-need-agree', function (value, element) {
        return $(element).is(':checked');
      }, 'Необходимо дать согласие');
      $.validator.addMethod('js-tel', function (value, element) {
        return this.optional(element) || value.replace(/\D+/g, '').length == 11;
      }, 'Некорректно заполнено поле');
      $.validator.addMethod('g-recaptcha-response', function (value, element) {
        $('#g-recaptcha').toggleClass('error', value);
        return value;
      }, 'Вы не робот?'); // Фокус по инпутам, тык на ошибки

      $(document).on('click', '[data-focuesd]', function () {
        var id = $(this).attr('data-focuesd');
        var $inputOrTextarea = $('input[id=' + id + '], textarea[id=' + id + ']'); // this.$refs[fieldName].$refs.input.focus()
        // this.$refs[fieldName].$refs.input.selectionEnd = this.$v.formEdit[fieldName].$model.length
        // this.$refs[fieldName].$refs.input.selectionEnd = this.$v.formEdit[fieldName].$model.length

        if ($inputOrTextarea.length) {
          $inputOrTextarea.focus();
        }
      });
      $('body').on('initValidation', function () {
        $('.js-form-validate').each(function () {
          var $this = $(this);
          var localSettings = {
            highlight: function highlight(element, errorClass, validClass) {
              $(element).parents('.b-input, .b-checkbox').addClass(errorClass).removeClass(validClass);
            },
            unhighlight: function unhighlight(element, errorClass, validClass) {
              $(element).parents('.b-input, .b-checkbox').removeClass(errorClass).addClass(validClass);
            },
            errorPlacement: function errorPlacement($error, $input) {
              if ($input.attr('type') === 'email') {
                $input.siblings('.js-msg').text($error.text());
              }
            },
            onkeyup: false
          };
          var settings = $.extend(localSettings, SETTINGS);
          var validator = $this.validate(settings);
          $this.on('input keyup change click', function (event) {
            $this.toggleClass('invalid', validator.numberOfInvalids() > 0);
          });
          $this.on('submit', function (event) {
            if (validator.numberOfInvalids() > 0) {
              event.stopImmediatePropagation();
            } else {
              var $form = $this;
              var url = $form.attr('action') || location.href;
              var method = $form.attr('method');
              var event = $form.data('event');
              var data = $form.serializeArray();
              var processData = true;
              var contentType = "application/json";

              if (method == 'POST') {
                data = new FormData($form[0]);
                processData = false;
                contentType = false;
              }

              $.ajax({
                url: url,
                method: method,
                data: data,
                processData: processData,
                contentType: contentType,
                success: function success(data) {
                  if (event && data && data.success) {
                    $form[0].reset();
                    $('body').trigger(event, data);
                  }
                }
              });
            }
          });
        });
      });
      $('.js-input').on('blur', function () {
        $(this).toggleClass('fill', $(this).text());
      });
      $('body').on('click', '.js-submit', function () {
        $(this).parents('form').trigger('submit');
      });
      $('body').trigger('initValidation');
    });
    ;

    if ($('.js-fixed-bar').length) {
      var windowsOS = navigator.userAgent.toLowerCase().indexOf('windows') !== -1;
      var barStaticPositionBar = 0;
      /* Проверка на скроллбар */

      var getScroll = function getScroll(scroll, selector) {
        var doc = document;
        var body = doc.body;
        var element = doc.querySelector(selector);
        var client = 'client' + scroll;
        scroll = 'scroll' + scroll;
        return /CSS/.test(doc.compatMode) ? element[client] < element[scroll] : body[client] < body[scroll];
      };
      /* Стартовая пермутация */


      var permutationMenu = function permutationMenu(element) {
        $('main').append(element);
      };
      /* Обратная пермутация */


      var backPermutationMenu = function backPermutationMenu(element) {
        $('.b-introduction__col:first-child').append(element);
      };
      /* Рассчет размеров */


      var calculateSize = function calculateSize(element, width) {
        var size = 0;
        $(element).children().each(function () {
          if (width) {
            size = size + $(this).outerWidth(true);
          } else {
            size = size + $(this).outerHeight(true);
          }
        });
        return size;
      };
      /* Рассчет отступа от верха страницы до элемента */


      var calculatePosition = function calculatePosition(el) {
        if (el.is('.b-page-wrapper')) {
          return;
        } else if (el.is('main')) {
          barStaticPositionBar = barStaticPositionBar + el.prev().outerHeight(true);
          calculatePosition(el.offsetParent());
        } else {
          barStaticPositionBar = barStaticPositionBar + el.position().top;
          calculatePosition(el.offsetParent());
        }
      };

      if ($('.js-fixed-bar.b-scroll-menu').length) {
        var barSize = calculateSize($('.js-fixed-bar.b-scroll-menu'));
        var barParent = $('.js-fixed-bar.b-scroll-menu').parent();
        calculatePosition($('.js-prev-fixed-bar'));
        barStaticPositionBar = barStaticPositionBar + $('.js-prev-fixed-bar').outerHeight(true);
        $(window).on('resize', function () {
          barStaticPositionBar = 0;
          calculatePosition($('.js-prev-fixed-bar'));
          barStaticPositionBar = barStaticPositionBar + barParent.outerHeight() - $('.js-prev-fixed-bar').position().top - barSize;
        });
      }
      /* Проверка координат */


      var checkPosition = function checkPosition() {
        $('.js-fixed-bar').each(function () {
          var bar = $(this);
          var isSrollMenu = bar.hasClass('b-scroll-menu');
          var line = isSrollMenu ? $('main') : $('.js-fixed-line');
          var lineOffsetTop = line.offset().top;
          var isStoryPage = isSrollMenu || line.hasClass('b-chapters-wrap');
          var storyPageVariables = ($(window).height() - calculateSize(bar, true)) / 2 - 20;

          if (isStoryPage && isSrollMenu) {
            if ($(window).innerWidth() >= 1920) {
              storyPageVariables = ($(window).height() - barSize) / 2 - 77 - 249;
            } else {
              storyPageVariables = ($(window).height() - barSize) / 2 - 75 - 235;
            }
          } else if (isStoryPage && !isSrollMenu) {
            if ($(window).innerWidth() >= 1920) {
              storyPageVariables = ($(window).height() - calculateSize(bar, true)) / 2 - 85 - 249;
            } else {
              storyPageVariables = ($(window).height() - calculateSize(bar, true)) / 2 - 85 - 235;
            }
          }

          if (windowsOS && getScroll('Height', '.b-page-wrapper') && isSrollMenu) {
            bar.addClass('windows');
          }

          if (isSrollMenu) {
            if (bar.offset().top - ($(window).height() - barSize) / 2 <= 0 && $('.b-page-wrapper').scrollTop() + ($(window).height() - barSize) / 2 >= barStaticPositionBar) {
              if (Math.abs(lineOffsetTop) + $(window).height() >= line.outerHeight() + storyPageVariables) {
                if (!bar.hasClass('endFixed')) {
                  permutationMenu(bar);
                }

                bar.removeClass('fixed').addClass('endFixed');
              } else {
                if (!bar.hasClass('fixed')) {
                  permutationMenu(bar);
                }

                bar.addClass('fixed').removeClass('endFixed');
              }
            } else {
              if (bar.hasClass('fixed') || bar.hasClass('endFixed')) {
                backPermutationMenu(bar);
              }

              bar.removeClass('fixed').removeClass('endFixed');
            }
          } else {
            if (lineOffsetTop <= 0) {
              if (Math.abs(lineOffsetTop) + $(window).height() >= line.outerHeight() + storyPageVariables) {
                bar.removeClass('fixed').addClass('endFixed');
              } else {
                bar.addClass('fixed').removeClass('endFixed');
              }
            } else {
              bar.removeClass('fixed').removeClass('endFixed');
            }
          }
        });
      };

      if ($(window).innerWidth() >= 1280) {
        $('.b-page-wrapper').on('scroll', checkPosition);
        $(window).on('resize', function () {
          $('.b-page-wrapper').off('scroll', checkPosition).on('scroll', checkPosition);
        });
      }
    }
    /* Скролл по якорям */


    if ($('.js-anchor').length) {
      var scrollTo = function scrollTo() {
        $('.b-page-wrapper').animate({
          scrollTop: $('#' + $(this).data('scroll')).offset().top + $('.b-page-wrapper').scrollTop()
        }, 1000);
      };

      $('.js-anchor').on('click', function () {
        scrollTo.call(this);
      });
    }

    if ($('.js-fixed-line.b-scroll-menu')) {
      var areas = $('*[data-area]');
      var areasCoordinates = [];

      var calculateAreasCoordinates = function calculateAreasCoordinates() {
        areas.each(function () {
          var $this = $(this);
          var areaTopCoordinates = 0;

          var calculateCoordinates = function calculateCoordinates(el) {
            if (el.is('.b-page-wrapper')) {
              return;
            } else if (el.is('main')) {
              areaTopCoordinates = areaTopCoordinates + el.prev().outerHeight(true);
              calculateCoordinates(el.offsetParent());
            } else {
              areaTopCoordinates = areaTopCoordinates + el.position().top;
              calculateCoordinates(el.offsetParent());
            }
          };

          calculateCoordinates($this);
          var areaCoordinates = {
            top: areaTopCoordinates,
            bottom: areaTopCoordinates + $this.outerHeight(),
            el: $this.data('area')
          };
          areasCoordinates.push(areaCoordinates);
        });
      };

      calculateAreasCoordinates();
      $(window).on('resize', function () {
        areasCoordinates = [];
        calculateAreasCoordinates();
      });
      $('.b-page-wrapper').on('scroll', function () {
        var scrollTop = $('.b-page-wrapper').scrollTop();
        areasCoordinates.forEach(function (item, i) {
          if (scrollTop >= item.top && scrollTop <= item.bottom) {
            $('[data-scroll="' + item.el + '"]').addClass('show');
          } else {
            $('.js-anchor').eq(i).removeClass('show');
          }
        });
      });
    }

    ;

    if ($('.js-load-more').length) {
      $(document).ready(function () {
        var pageNumb = 2;
        var pageCouner = null;

        function getListItems(html) {
          var $container = $('<div>' + html + '</div>');
          return $container.children();
        }

        function animationAppend(_ref, callback) {
          var target = _ref.target,
              $list = _ref.$list,
              $button = _ref.$button,
              toggle = _ref.toggle;
          var $target = $(target); // var $container = $('<div>' + html + '</div>');
          // var $list = $container.children();

          var duration = 50;
          var promisess = [];
          pageCouner = $list.length;
          $list.fadeOut(function () {
            // let count = $list.length -1;
            $list.each(function (index) {
              var $card = $(this);
              $target.append($card);
              promisess.push(new Promise(function (resolve) {
                $card.delay(duration * index).fadeIn(function () {
                  resolve();
                });
              }));
            });
          }); // Конец анимации

          Promise.all(promisess).then(function () {
            setTimeout(function () {
              if (toggle) {
                $button.css({
                  visibility: ''
                });
              }

              callback();
            }, 500);
          });
        } // $(document).on('click initLoad', '.js-load-more', function () {


        $(document).on('click initLoad', '.js-load-more', function () {
          var $this = $(this);
          var data = $this.data();
          var url = data.url,
              _data$target = data.target,
              target = _data$target === undefined ? '.js-gallery-list' : _data$target,
              _data$template = data.template,
              tpl = _data$template === undefined ? '.js-load-more-template' : _data$template,
              pagen = data.pagen,
              page = data.page,
              next = data.next,
              _data$section = data.section,
              section = _data$section === undefined ? '' : _data$section;
          var template = '{{#items}}' + $(tpl).html() + '{{/items}}';
          var sendParams = {};
          $this.css({
            visibility: 'hidden'
          });

          if (section) {
            sendParams.section_id = section;
          }

          var success = function success(res) {
            var html = Mustache.render(template, res);
            var $list = getListItems(html);
            pageNumb++;
            next -= $list.length;
            $this.attr('data-next', next);
            $this.data('next', next);
            $this.find('.js-count').text(next);
            html && animationAppend({
              target: target,
              $list: $list,
              $button: $this,
              toggle: Boolean(next)
            }, function () {
              window.gallerySwiperRefresh && gallerySwiperRefresh();
            });
          };

          sendParams[pagen] = pageNumb;

          if (pageNumb <= page) {
            $.ajax({
              url: url,
              data: sendParams,
              success: success
            });
          }
        }); // let is_mobile = window.innerWidth < 768;
        // let page = is_mobile?4:6;
        // $('.js-load-more').data({ page });
        // $('.js-load-more').data({page}).trigger('initLoad');
      });
    }

    if ($('.js-more-quote').length) {
      var showMoreQuote = function showMoreQuote() {
        $(this).prev().addClass('show-more');
      };

      var countQuotes = function countQuotes() {
        var countQuotes = $('.js-more-quote').prev().find('.b-chapter__content').children().length;
        $('.js-quote-count').text(countQuotes - 6);
      };

      $('.js-more-quote').on('click', function () {
        showMoreQuote.call(this);
      });
      $(document).ready(countQuotes);
    }

    ;

    (function () {
      /* eslint-disable */
      $(document).on('click', '.js-trigger-event', function () {
        var event = $(this).data('event') || '';

        if (event) {
          $('body').trigger(event);
        }
      });
      $('body').on('feedback-form', function () {
        var $formFeedback = $('.js-form-feendback');
        var $successFeedback = $('.js-success-feendback');

        if ($formFeedback.length && $successFeedback.length) {
          $successFeedback.fadeOut(function () {
            $formFeedback.fadeIn();
          });
        }
      });
      $('body').on('feedback-success', function () {
        var $formFeedback = $('.js-form-feendback');
        var $successFeedback = $('.js-success-feendback');

        if ($formFeedback.length && $successFeedback.length) {
          $formFeedback.fadeOut(function () {
            $successFeedback.fadeIn();
          });
        }
      });
    })();

    ;

    if ($('.js-fadeIn').length) {
      $(document).ready(function () {
        $('.js-fadeIn').addClass('start-animate');
      });
    }

    ;
  }

  ;
  document.addEventListener("DOMContentLoaded", documentReady);
})();